import React from 'react';
import style from './accommodations.module.css';

function hotel(name, addr, url) { return { name, addr, url } };

const hotels = [
  new hotel(
    'Hampton Inn Garden City',
    <>1 North Ave<br />Garden City, NY 11530</>,
    'https://www.google.com/travel/hotels/entity/CgsI2tL9z5uu2vyKARAB/overview'
  ),
  new hotel(
    'Holiday Inn Westbury',
    <>369 Old Country Rd<br />Carle Place, NY 11514</>,
    'https://www.google.com/travel/hotels/entity/CgoI2avlr86U4LoYEAE/overview'
  ),
  new hotel(
    'Homewood Suites by Hilton',
    <>40 Westbury Ave<br />Carle Place, NY 11514</>,
    'https://www.google.com/travel/hotels/entity/CgoIsoCFsdi-i4RIEAE/overview'
  ),
  new hotel(
    'Hyatt Place Garden City',
    <>5 North Ave<br />Garden City, NY 11530</>,
    'https://www.google.com/travel/hotels/entity/CgsIj-L3mMyUxZDQARAB/overview'
  ),
  new hotel(
    'The Roslyn Hotel',
    <>1221 Old Northern Blvd<br />Roslyn, NY 11576</>,
    'https://www.google.com/travel/hotels/entity/CgoItrzUvq-ekZJFEAE/overview'
  ),
  new hotel(
    'SpringHill Suites by Marriott',
    <>20 Westbury Ave<br />Carle Place, NY 11514</>,
    'https://www.google.com/travel/hotels/entity/CgoIouut7-rgsaUSEAE/overview'
  ),
];

const Hotel = ({ name, addr, url }) => (
  <div className={style.hotel}>
    <a href={url} target='_blank' rel='noopener noreferrer'>
      <b>{name}</b>
      <span>{addr}</span>
    </a>
  </div>
);

const Accommodations = () => {
  return (
    <div className={style.hotelList}>
      {hotels.map(hotel => <Hotel {...hotel} key={hotel.name} />)}
    </div>
  );
}

export default Accommodations;