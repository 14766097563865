import React from 'react';
import style from './copy.module.css';

const Copy = () => (
  <div className={style.container}>
    <h2 className={style.subhead}>Kindly join us for the wedding of</h2>
    <h1 className={style.heading}>Sharee <br />&amp; Darrell</h1>
    <span className={style.divider} />
    <p className={style.posthead}>
      To take place on <abbr title='August 31st'>Aug 31<sup>st</sup></abbr> 2019 at 3pm
      <br />Williston Park, NY
    </p>
    <a
      href='https://forms.gle/yvQnAUejtwk1t3Mb8'
      target='_blank'
      rel='nofollow noopener noreferrer'
      className={style.rsvpBtn}
    >
      RSVP
    </a>
  </div>
);

export default Copy;
