import React, { Component } from 'react';
import { BrowserRouter as Router, withRouter, Route, Link } from 'react-router-dom';
import Copy from './components/copy';
import Accomidations from './components/accommodations';
import Background from './components/background';
import './App.css';

class App extends Component {
  render() {
    return (
      <Router>
        <div className='container'>
          <Background />
          <InnerApp />
        </div>
      </Router>
    );
  }
}

const InnerApp = withRouter(props => (
  <div className='pagewrap'>
    <nav className='main-nav'>
      <Link
        className={props.location.pathname === '/' ? 'active-route' : undefined }
        to='/'
      >
        RSVP
      </Link>
      <Link
        className={props.location.pathname === '/accommodations' ? 'active-route': undefined}
        to='/accommodations'
      >
        Accommodations
      </Link>
      <a
        href='https://www.amazon.com/wedding/sharee-miller-darrell-hanley-long-island-august-2019/registry/VK7KVW8ZA3TB'
        target='_blank'
        rel='nofollow noreferrer noopener'
      >
        Registry
      </a>
    </nav>
    <Route path='/' exact component={Copy} />
    <Route path='/accommodations' component={Accomidations} />
  </div>
));


export default App;
