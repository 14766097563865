import React from 'react';
import { withRouter } from 'react-router-dom';
import Sun from '../assets/images/sun.svg';
import MountainLeft from '../assets/images/mountain-left.svg';
import MountainCenter from '../assets/images/mountain-center.svg';
import MountainRight from '../assets/images/mountain-right.svg';
import CloudsLeft from '../assets/images/clouds-left.svg';
import CloudsRight from '../assets/images/clouds-right.svg';
import Couple from '../assets/images/couple.svg';
import style from './background.module.css'

const Background = withRouter(props => (
  <div className={[style.container, props.location.pathname !== '/' ? style.passive : undefined].join(' ')}>
    <img className={style.sun} src={Sun} alt='' />
    <img className={[style.mountain, style.right].join(' ')} src={MountainRight} alt='' />
    <img className={[style.cloud, style.right].join(' ')} src={CloudsRight} alt='' />
    <img className={[style.mountain, style.middle].join(' ')} src={MountainCenter} alt='' />
    <img className={[style.cloud, style.left].join(' ')} src={CloudsLeft} alt='' />
    <img className={[style.mountain, style.left].join(' ')} src={MountainLeft} alt='' />
    <img className={style.couple} src={Couple} alt='' />
  </div>
));

export default Background;
